import Footer from "@/Components/Footer";
import Logo from "@/Components/Logo";
import NavbarNavLeft from "@/Components/Navigation/NavbarNavLeft";
import NavbarNavRight from "@/Components/Navigation/NavbarNavRight";
import VerificationNotice from "@/Components/VerificationNotice";
import { PageProps } from "@/types";
import { Link, usePage } from "@inertiajs/react";
import { PropsWithChildren } from "react";
import { Navbar } from "react-bootstrap";

export default function SiteLayout({ children }: PropsWithChildren) {
    const { auth } = usePage<PageProps>().props;

    return (
        <>
            <VerificationNotice
                show={auth.user && !auth.user.email_verified_at}
            />

            <Navbar
                expand="lg"
                bg="body-tertiary"
                className="border-bottom sticky-top top-0 start-0"
            >
                <div className="container">
                    <Navbar.Brand as={Link} href={route("pages.home")}>
                        <Logo loading="eager" />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="navbarSupportedContent" />

                    <Navbar.Collapse id="navbarSupportedContent">
                        <NavbarNavLeft />
                        <NavbarNavRight />
                    </Navbar.Collapse>
                </div>
            </Navbar>

            {children}

            <div className="container">
                <Footer />
            </div>
        </>
    );
}
